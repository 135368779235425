import * as React from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import * as styles from "../styles/post-item-list.module.css"

const Sublinks = ({ links }) => (
	<div className={styles.sublinks}>
		{links.map(link => {
			if (link.nonStandard)
				return <a className={styles.sublink} href={link.path} children={link.text} />
			else
				return <Link className={styles.sublink} to={link.path} children={link.text} />
		})}
	</div>
)

const PostItem = ({ post }) => (
	<div className={styles.postItem}>
		{/*<div className={styles.postItemThumbnailBox}>
			<div className={styles.postItemThumbnail}>{post.frontmatter.image ? <img src={post.frontmatter.image} /> : ""}</div>
		</div>*/}
		<div className={styles.postItemContent}>
			<div className={[styles.postItemDate, post.frontmatter.hideDate ? 'hide' : ''].join(' ')}>{post.frontmatter.date}</div>
			<Link className={styles.postItemTitle} to={post.fields.slug}>
				{post.frontmatter.title}
			</Link>
			{!post.frontmatter.hideSummary && post.frontmatter.summary && <div dangerouslySetInnerHTML={{ __html: post.frontmatter.summary }} />}
			{post.frontmatter.sublinks && post.frontmatter.sublinks.length && <Sublinks links={post.frontmatter.sublinks} />}
		</div>
	</div>
)

const PostItemList = ({ posts, className, children }) => (
	<div className={className}>
		<div className={styles.postItemList}>
			{posts.map(post => (
				<PostItem post={post} key={post.id} />
			))}
		</div>
		<div>{children}</div>
	</div>
)

export default PostItemList
