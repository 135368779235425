import * as React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Helmet } from "react-helmet"

import Seo from '../components/seo'
import Layout from '../components/layout'
import PostItemList from "../components/post-item-list"

import * as styles from "../styles/index.module.css"

const MathPage = () => {
	const data = useStaticQuery(graphql`
		query {
      blog: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
          frontmatter: { 
            category: { eq: "blog" },
            publish: { eq: true }
          } 
        }
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "YYYY MMMM DD")
              summary
            }
            excerpt
            fields {
              slug
            }
          }
        }
      }
    }
	`)
  React.useEffect(() => {
    document.body.classList.add('blog')
    return () => document.body.classList.remove('blog')
  }, [])
	return (
		<Layout>
      <Helmet>
      </Helmet>

      {/*<script data-inline-script>document.body.classList.add('blog')</script>*/}
      <div>
  			<h1 className={styles.title}>blog</h1>
        <PostItemList className="Section" posts={data.blog.edges.map(edge => edge.node)} />
      </div>
		</Layout>
	)
}

export const Head = () => <Seo title="blog" description="A walled garden of quiet reflection. Sometimes."/>

export default MathPage